<template>
  <div>
    <el-page-header @back="goBack" content="Customers">
    </el-page-header>
    <el-form ref="form" :model="formModel" :inline="true" @submit.native.prevent>
        <form-gen :form-desc="formDesc" :model="formModel" />
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    <el-table
      v-loading="loading"
      :data="tableData"
      height="calc(100vh - 200px)"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="Customer"
        width="250">
        <template #default="scope">
          <div class="name-wrapper">
            <el-button type="primary" @click="edit(scope.row.id)">
              {{ scope.row.name }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="offer"
        label="Offer"
        width="180">
      </el-table-column>
      <el-table-column
        prop="createdOn"
        label="Created On"
        >
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import FormGen from '@/components/FormGen.vue'
import ListView from '@/components/ListView.vue'
import { InputKind, FormDesc } from '@/types/form'
import { Customer, CustomerRow } from '@/types/customerState'

@Options({
  components: {
    FormGen
  }
})
export default class CustomersList extends mixins(ListView) {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: false,
        errors: null,
        kind: InputKind.InputText,
        label: 'Search',
        name: 'search'
      }]
    }]
  }

  get modelData (): Array<Customer> | null {
    return this.$store.getters[this.getPath('get', 'list')]
  }

  get tableData (): Array<CustomerRow> {
    const table: Array<CustomerRow> = []
    if (this.modelData) {
      this.modelData.forEach((customer: Customer) => {
        table.push({
          id: customer.id,
          name: customer.name,
          offer: customer.offer,
          createdOn: customer.createdOn
        })
      })
    }

    return table
  }

  getEditRouteName (): string {
    const name = `${this.getCurrentRouteName()}-edit`
    return name
  }

  edit (id: string): void {
    this.$router.push({ name: this.getEditRouteName(), params: { id: id } })
    console.log('ID', id, '-->', this.getEditRouteName())
  }
}
</script>
