
import { Options, mixins } from 'vue-class-component'

import FormGen from '@/components/FormGen.vue'
import ListView from '@/components/ListView.vue'
import { InputKind, FormDesc } from '@/types/form'
import { Customer, CustomerRow } from '@/types/customerState'

@Options({
  components: {
    FormGen
  }
})
export default class CustomersList extends mixins(ListView) {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: false,
        errors: null,
        kind: InputKind.InputText,
        label: 'Search',
        name: 'search'
      }]
    }]
  }

  get modelData (): Array<Customer> | null {
    return this.$store.getters[this.getPath('get', 'list')]
  }

  get tableData (): Array<CustomerRow> {
    const table: Array<CustomerRow> = []
    if (this.modelData) {
      this.modelData.forEach((customer: Customer) => {
        table.push({
          id: customer.id,
          name: customer.name,
          offer: customer.offer,
          createdOn: customer.createdOn
        })
      })
    }

    return table
  }

  getEditRouteName (): string {
    const name = `${this.getCurrentRouteName()}-edit`
    return name
  }

  edit (id: string): void {
    this.$router.push({ name: this.getEditRouteName(), params: { id: id } })
    console.log('ID', id, '-->', this.getEditRouteName())
  }
}
