
import { Options, Vue } from 'vue-class-component'

import { FormErrors, HttpError } from '@/types/errors'
import { FormDesc } from '@/types/form'

@Options({
  components: {
    // FormGen
  }
})
export default class ModelView extends Vue {
  capitalize (str: string): string {
    return `${str[0].toUpperCase()}${str.slice(1)}`
  }

  getCurrentRouteName (): string {
    let routeName = ''
    if (this.$route && this.$route.name) {
      routeName = this.$route.name.toString().substr(
        0, this.$route.name.toString().indexOf('-'))
    }
    return routeName
  }

  getPath (verb: string, scope: string): string {
    let capitalized = ''
    const routeName = this.getCurrentRouteName()
    capitalized = this.capitalize(scope)
    return `${routeName}/${verb}${capitalized}`
  }

  goBack (): void {
    this.$router.go(-1)
  }

  setErrors (error: HttpError, formDesc?: FormDesc | null) {
    if (error.status === 500 || error.status === 502 || error.status === 503) {
      this.$router.push({ name: '500' })
    } else if (error.status === 404) {
      this.$router.push({ name: '404' })
    } else if (error.status === 401 || error.status === 403) {
      this.$store.dispatch('global/logout')
      this.$router.push({ name: 'login' })
    } else if (error.status === 400) {
      if (formDesc) {
        const errors: FormErrors = {
          status: error.status,
          data: {}
        }
        error.data.errors.forEach((err: string) => {
          const [label, value] = err.split(':')
          if (!errors.data[label]) {
            errors.data[label] = []
          }
          errors.data[label].push(value)
        })
        this.setFormErrors(errors, formDesc)
      }
    }
  }

  setFormErrors (errors: FormErrors, formDesc: FormDesc) {
    formDesc.nonFieldErrors = null
    for (const name in errors.data) {
      if (name === 'non_field_errors') {
        formDesc.nonFieldErrors = errors.data[name]
      } else {
        formDesc.fieldsets.forEach((fieldset) => {
          fieldset.fields.forEach((field) => {
            if (field.name === name) {
              field.errors = errors.data[name]
            }
          })
        })
      }
    }
  }
}

